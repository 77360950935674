import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.OrphanEventCalendarJoinsServiceProxy,
        ApiServiceProxies.JobApplicationsServiceProxy,
        ApiServiceProxies.OrphanCandidateFormJoinsServiceProxy,
        ApiServiceProxies.OrphanCandidateFormsServiceProxy,
        ApiServiceProxies.OrphanCandidateQuestionOptionsServiceProxy,
        ApiServiceProxies.OrphanCandidateQuestionsServiceProxy,
        ApiServiceProxies.QuestionCategoriesServiceProxy,
        ApiServiceProxies.CandidateQuestionTypesServiceProxy,
        ApiServiceProxies.PublicContentsServiceProxy,
        ApiServiceProxies.OrphanUpdateTransactionsServiceProxy,
        ApiServiceProxies.RepresentativesServiceProxy,
        ApiServiceProxies.RegionBranchsServiceProxy,
        ApiServiceProxies.NationalitiesServiceProxy,
        ApiServiceProxies.WorkTaskRefsServiceProxy,
        ApiServiceProxies.OrphanTasksServiceProxy,
        ApiServiceProxies.RegionsServiceProxy,
        ApiServiceProxies.CareerSpecialistFormsServiceProxy,
        ApiServiceProxies.AyniNakdiYardimsServiceProxy,
        ApiServiceProxies.AyniYardimParametersServiceProxy,
        ApiServiceProxies.AnnouncementsServiceProxy,
        ApiServiceProxies.NewsAndAnnouncementsServiceProxy,
        ApiServiceProxies.OrphanTaskTypesServiceProxy,
        ApiServiceProxies.AgeLimitsServiceProxy,
        ApiServiceProxies.EventCalendarDetailsServiceProxy,
        ApiServiceProxies.OrphanEventTypesServiceProxy,
        ApiServiceProxies.JobStatuesServiceProxy,
        ApiServiceProxies.WorkJobsServiceProxy,
        ApiServiceProxies.BookTypesServiceProxy,
        ApiServiceProxies.AvailabilityStatusesServiceProxy,
        ApiServiceProxies.EducationTypesServiceProxy,
        ApiServiceProxies.EducationStatusesServiceProxy,
        ApiServiceProxies.OrphansServiceProxy,
        ApiServiceProxies.KizilayMernisServiceProxy,
        ApiServiceProxies.NeighborhoodsServiceProxy,
        ApiServiceProxies.DistrictsServiceProxy,
        ApiServiceProxies.ProvincesServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
