/* tslint:disable:no-trailing-whitespace */
import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {}

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'flaticon-line-graph',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-squares', '/app/admin/tenants'),

            new AppMenuItem('Parameters','','flaticon-signs-1','',[],[
                new AppMenuItem('Nationalities', 'Pages.Nationalities', 'flaticon2-location', '/app/main/userNationality/nationalities'),
                new AppMenuItem('Provinces', 'Pages.Provinces', 'flaticon2-architecture-and-city', '/app/main/provinces/provinces'),

                new AppMenuItem('Districts', 'Pages.Districts', 'flaticon2-rhombus', '/app/main/districts/districts'),

                new AppMenuItem('Neighborhoods', 'Pages.Neighborhoods', 'flaticon2-send-1', '/app/main/neighborhoods/neighborhoods'),
                new AppMenuItem('EducationStatues', 'Pages.EducationStatuses', 'flaticon2-user-outline-symbol', '/app/main/educationStatues/educationStatuses'),
                new AppMenuItem('EducationTypes', 'Pages.EducationTypes', 'flaticon2-graph', '/app/main/educationTypes/educationTypes'),
                new AppMenuItem('AvailabilityStatuses', 'Pages.AvailabilityStatuses', 'flaticon2-heart-rate-monitor', '/app/main/availabilityStatues/availabilityStatuses'),
                new AppMenuItem('OrphanBooktypes', 'Pages.BookTypes', 'flaticon2-open-text-book', '/app/main/orphanBooktypes/bookTypes'),
                new AppMenuItem('AgeLimits', 'Pages.AgeLimits', 'flaticon-interface-6', '/app/main/orphanAgeLimits/ageLimits'),


            ]),
            new AppMenuItem('OrphansManagement','','flaticon-rotate','',[],[

                new AppMenuItem('Orphans', 'Pages.Orphans', 'flaticon2-group', '/app/main/orphans/orphans'),
                new AppMenuItem('OrphanUpdateTransactions', 'Pages.OrphanUpdateTransactions', 'flaticon-information', '/app/main/orphans/orphanUpdateTransactions'),

            ]),
            new AppMenuItem('Ayni/Nakdi Yardım','','flaticon-edit-1','',[],[

                new AppMenuItem('AyniYardimParameters', 'Pages.AyniYardimParameters', 'flaticon2-box', '/app/main/ayniYardim/ayniYardimParameters'),
                new AppMenuItem('AyniNakdiYardims', 'Pages.AyniNakdiYardims', 'flaticon-price-tag', '/app/main/ayniYardim/ayniNakdiYardims'),
            ]),
            new AppMenuItem('WorkManagement','','flaticon-customer','',[],[

                new AppMenuItem('JobStatues', 'Pages.JobStatues', 'flaticon-more', '/app/main/orphanJobs/jobStatues'),
                new AppMenuItem('WorkJobs', 'Pages.WorkJobs', 'flaticon-more', '/app/main/orphanJobs/workJobs'),
                new AppMenuItem('JobApplications', 'Pages.JobApplications', 'flaticon2-user', '/app/main/orphanJobs/jobApplications'),

            ]),

            new AppMenuItem('OrphanEventManagement','','flaticon-alert','',[],[
                new AppMenuItem('OrphanEventTypes', 'Pages.OrphanEventTypes', 'flaticon-more', '/app/main/orphanEvents/orphanEventTypes'),
                new AppMenuItem('EventCalendarDetails', 'Pages.EventCalendarDetails', 'flaticon-more', '/app/main/orphanEventCalendar/eventCalendarDetails'),
                new AppMenuItem('OrphanEventCalendarJoins', 'Pages.OrphanEventCalendarJoins', 'flaticon-more', '/app/main/orphanEventCalendar/orphanEventCalendarJoins'),

            ]),

            new AppMenuItem('OrphanTaskManagement','','flaticon-security','',[],[
                new AppMenuItem('OrphanTaskTypes', 'Pages.OrphanTaskTypes', 'flaticon-more', '/app/main/orphanTask/orphanTaskTypes'),
                new AppMenuItem('Görev Atama', 'Pages.OrphanTasks', 'flaticon-event-calendar-symbol', '/app/main/orphanWorkTask/orphanTasks'),
            ]),

           new AppMenuItem('NewsAndAnnouncements', 'Pages.NewsAndAnnouncements', 'flaticon-tea-cup', '/app/main/announcements/newsAndAnnouncements'),

            new AppMenuItem('Announcements', 'Pages.Announcements', 'flaticon-bell', '/app/main/announcements/announcements'),






            new AppMenuItem('CareerSpecialistForms', 'Pages.CareerSpecialistForms', 'flaticon-book', '/app/main/careerSpecialist/careerSpecialistForms'),

            // new AppMenuItem('Toplu Görev Atama', '', 'flaticon-more', '/app/main/batchAssignment/batch-assigment-form'),




            new AppMenuItem('RegionBrancMenuName','','flaticon2-graphic-1','',[],[
                new AppMenuItem('Regions', 'Pages.Regions', 'flaticon-more', '/app/main/regionArea/regions'),
                new AppMenuItem('RegionBranchs', 'Pages.RegionBranchs', 'flaticon-more', '/app/main/regionArea/regionBranchs'),
                new AppMenuItem('Representatives', 'Pages.Representatives', 'flaticon-more', '/app/main/regionArea/representatives'),

            ]),


            new AppMenuItem('PublicContents', 'Pages.PublicContents', 'flaticon-instagram-logo', '/app/main/publicManagerContents/publicContents'),

            new AppMenuItem('OrphanCandidateManager','','flaticon2-graphic-1','',[],[
                new AppMenuItem('OrphanCandidateForms', 'Pages.OrphanCandidateForms', 'flaticon-more', '/app/main/orphanCandidateManager/orphanCandidateForms'),
                new AppMenuItem('OrphanCandidateFormJoins', 'Pages.OrphanCandidateFormJoins', 'flaticon-more', '/app/main/orphanCandidateManager/orphanCandidateFormJoins'),
                // new AppMenuItem('CandidateQuestionTypes', 'Pages.CandidateQuestionTypes', 'flaticon-more', '/app/main/orphanCandidateManager/candidateQuestionTypes'),

                new AppMenuItem('QuestionCategories', 'Pages.QuestionCategories', 'flaticon-more', '/app/main/orphanCandidateManager/questionCategories'),

                // new AppMenuItem('OrphanCandidateQuestions', 'Pages.OrphanCandidateQuestions', 'flaticon-more', '/app/main/orphanCandidateMAnager/orphanCandidateQuestions'),
                //
                // new AppMenuItem('OrphanCandidateQuestionOptions', 'Pages.OrphanCandidateQuestionOptions', 'flaticon-more', '/app/main/orphanCandidateManager/orphanCandidateQuestionOptions'),
                //


            ]),








             new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem(
                'Administration',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'flaticon-map',
                        '/app/admin/organization-units'
                    ),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
            // new AppMenuItem('Orphans', 'Pages.Administration.Orphans', 'flaticon-more', '/app/admin/orphans/orphans'),

            // new AppMenuItem('Neighborhoods', 'Pages.Administration.Neighborhoods', 'flaticon-more', '/app/admin/neighborhoods/neighborhoods'),

            // new AppMenuItem('Districts', 'Pages.Administration.Districts', 'flaticon-more', '/app/admin/districts/districts'),

                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'flaticon-tabs',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'flaticon-folder-1',
                        '/app/admin/auditLogs'
                    ),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'flaticon-lock',
                        '/app/admin/maintenance'
                    ),
                    new AppMenuItem(
                        'Subscription',
                        'Pages.Administration.Tenant.SubscriptionManagement',
                        'flaticon-refresh',
                        '/app/admin/subscription-management'
                    ),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'flaticon-medical',
                        '/app/admin/ui-customization'
                    ),
                    new AppMenuItem(
                        'WebhookSubscriptions',
                        'Pages.Administration.WebhookSubscription',
                        'flaticon2-world',
                        '/app/admin/webhook-subscriptions'
                    ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'flaticon-interface-8',
                        '/app/admin/dynamic-property'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'flaticon-settings',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'flaticon-settings',
                        '/app/admin/tenantSettings'
                    ),
                ]
            ),
            new AppMenuItem(
                'DemoUiComponents',
                'Pages.DemoUiComponents',
                'flaticon-shapes',
                '/app/admin/demo-ui-components'
            ),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
